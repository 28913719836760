import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";

export default function AddNewModal({
  show,
  onHide,
  title,
  api,
  data,
  refresh,
}) {


  const [name, setName] = useState(
    (title === "Update" && data?.categoryName) || data?.subCategoryName
  );
  const [loader, setLoader] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    const postData = {
      name: name,
    };
    if (!data?._id) {
      postData.testName = data?.testName;
    }
    if (data?._id) {
      postData.id = data?._id;
    }
    if (data?.categoryId && typeof data.categoryId !== "object") {
      postData.categoryId = data.categoryId;
    }

    setLoader(true);
    try {
      const response = await api(postData);
      onHide();
      refresh();
    } catch (e) {
      toast.error(e?.response?.data?.data || e?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>{title}</Modal.Header>
      <form onSubmit={handleSubmit} className="p-3">
        <div className="form-group">
          <input
            type="text"
            value={name}
            className="form-control"
            placeholder="Enter name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div>
          <button className="btn btn-sm btn-primary w-100" type="submit">
            {loader ? <Spinner animation="border" size="sm" /> : "Save"}
          </button>
        </div>
      </form>
    </Modal>
  );
}
