import React, { useEffect, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { useNavigate } from "react-router-dom";
import {
  addSubjectApi,
  deleteSubjectApi,
  editSubjectApi,
  getSubjectApi,
} from "../components/APIs/Api";
import animationData from "..//../images/loding.json";
import toast from "react-hot-toast";
import { Card, Dropdown, Spinner, Table } from "react-bootstrap";
import AddNewModal from "../modal/AddNewModal";
import moment from "moment";
import Lottie from "lottie-react";
import Pagination from "../components/Common/Pagination";

const svg1 = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

export default function PaidCategory() {
  const [subjects, setSubjects] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isAddCategory, setIsAddCategory] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState(null);
  const [isEditCategory, setIsEditCategory] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const itemsPerPage = 10;

  const [data, setData] = useState({
    testName: "STCW.95",
  });
  const navigate = useNavigate();

  async function fetchSubjectData() {
    setLoader(true);
    try {
      const res = await getSubjectApi(
        "STCW.95",
        currentPage,
        itemsPerPage
      );
      setSubjects(res?.data?.data);
    } catch (error) {
      toast.error(
        error?.response?.data?.data || error?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }

  //=========pagination=============
  const totalItems = subjects?.categoryCount;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  async function handleDelete(id) {
    try {
      const res = await deleteSubjectApi(id);
      toast.success("Subject deleted successfully");
      fetchSubjectData();
    } catch (error) {
      toast.error(
        error?.response?.data?.data || error?.response?.data?.message
      );
    }
  }

  useEffect(() => {
    fetchSubjectData();
  }, [currentPage]);

  return (
    <div>
      <div>
        <PageTitle
          activeMenu="Subjects List"
          motherMenu="STCW.95 Module"
          isButton={true}
          action={setIsAddCategory}
        />
      </div>
      {loader ? (
        <div className="">
          <Lottie
            style={{ height: "200px", marginTop: "45px", padding: "30px" }}
            animationData={animationData}
          />
        </div>
      ) : (
        <Card>
          <Card.Body>
            <Table>
              <thead style={{ color: "black" }}>
                <tr>
                  <th>
                    <strong>test NAME</strong>
                  </th>
                  <th>
                    <strong>Subject Name</strong>
                  </th>
                  <th>
                    <strong>created at</strong>
                  </th>

                  <th>
                    <strong>ACTION</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {subjects?.categoryListing?.map((item, i) => (
                  <tr key={item.id}>
                    <td>
                      {item?.testName?.length > 15
                        ? item?.testName.slice(0, 15) + "..."
                        : item?.testName}
                    </td>
                    <td>
                      {item?.categoryName?.length > 20
                        ? item?.categoryName.slice(0, 20) + "..."
                        : item?.categoryName}
                    </td>
                    <td>{moment(item.createdAt).format("ll")}</td>

                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="info light"
                          className="light sharp btn btn-info i-false"
                        >
                          {svg1}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() =>
                              navigate("/sub-Categories", { state: item })
                            }
                          >
                            Categories
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              setSelectedSubjects(item);
                              setIsEditCategory(true);
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDelete(item._id)}>
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {/* )} */}
            {subjects?.categoryCount === 0 && (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            )}
            {subjects?.categoryCount !== 0 && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Total Subjects <span>{totalItems}</span>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "10px" }}
                >
                  <Pagination
                    pageCount={totalPages}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      )}

      {isAddCategory && (
        <AddNewModal
          show={isAddCategory}
          onHide={() => setIsAddCategory()}
          title={"Add New Subject"}
          api={addSubjectApi}
          refresh={fetchSubjectData}
          data={data}
        />
      )}
      {isEditCategory && (
        <AddNewModal
          show={isEditCategory}
          onHide={() => setIsEditCategory()}
          title={"Update"}
          refresh={fetchSubjectData}
          api={editSubjectApi}
          data={selectedSubjects}
        />
      )}
    </div>
  );
}
