import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import defaultImage from "../../images/defaultDp.jpg";
// import { KEYCHAIN } from '../utils/keychain';

const S3Image = ({ imageKey }) => {
  const [imageData, setImageData] = useState(imageKey ? imageKey : null);



  const onImageError = (e) => {
    // e.target.src = defaultImage;
  };

  useEffect(() => {
    const fetchImageData = async () => {
      if (!imageKey) {
        return;
      }
      try {
        const s3 = new AWS.S3();
        const params = {
          Bucket: process.env.REACT_APP_BUCKET_NAME,
          Key: imageKey?.split('/')[imageKey?.split('/')?.length - 1],
        };
        const data = await s3.getObject(params).promise();
        const base64Image = data.Body.toString("base64");

        setImageData(base64Image);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImageData();
  }, [imageKey]);


  const [base64Image, setBase64Image] = useState(null);

  // Function to convert the image URL to Base64
  const urlToBase64 = async (url) => {
    try {
      // Fetch the image from the provided URL
      const response = await fetch(url);
      const blob = await response.blob();

      // Convert the blob to Base64 using FileReader
      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Image(reader.result); // Set the Base64 string to state
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.error('Error converting image to Base64:', error);
    }
  };

  // Example image URL (you can change it to your own URL)
  // const imageUrl = 'https://oceanxs.s3.eu-west-2.amazonaws.com/download%20%285%29%20%282%29.jpeg';

  // Use useEffect to call the function on component mount
  useEffect(() => {
    urlToBase64(imageKey);
  }, []);



  if (!base64Image) {
    return (
      <div>
        <img height={60} width={60} src={defaultImage} />
      </div>
    );
  }

  return (
    <div>

      <img
        height={60}
        width={60}
        className="profile-img"
        src={imageKey ? base64Image : defaultImage}
        // src={`data:image/png;base64,${imageData}`}
        alt="S3 Content"
        onError={onImageError}
      />
    </div>
  );
};

export default S3Image;
